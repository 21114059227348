import React, { useEffect } from 'react'
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import { Link } from 'gatsby'

import Container from 'react-bootstrap/Container'
import { Row, Col } from 'react-bootstrap'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import Seo from '../components/seo'
import Layout from '../components/layout'
import LocatorCallOut from '../components/sections/locator-call-out'
import BigIngredients from '../components/sections/big-ingredients'
import RTBs from '../components/sections/rtbs'

import MainCTA from '../components/sections/main-cta'
import CookingTipsTiles from '../components/sections/cooking-tips-tiles'
import CarouselTestimonials from '../components/sections/carousel-testimonials'
import FeaturedCarousel from '../components/sections/carousel-featured-content'

gsap.registerPlugin(ScrollTrigger);

const HomePage = () => {

  useEffect(() => {
    const navbar = document.querySelector('#navBar');

    ScrollTrigger.create({
      start: 300, // Start the trigger at 300px scroll
      onToggle: self => {
        if (self.isActive) {
          navbar.classList.add('nav-active');
        } else {
          navbar.classList.remove('nav-active');
        }
      },
    });

    // Cleanup function
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);

  const theme = useTheme()
  // find out if we're on mobile to load the right color button
  const isMobile = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true })
  const heroVariant = isMobile ? 'light' : 'primary'

  return (
    <Layout className="home">
      <Seo title="It's Meati™" description="There's a new meat in town. Made from nutrient-rich mushroom root — aka the energy source Mother Nature intended. Now that's Good Energy™." />
      <header id="homepageHero" className="text-start stack-parent">
        <div className="stack-child">
          <div className="video-container">
            <iframe
              src={'https://player.vimeo.com/video/759314833?h=c983552d71&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&loop=1&muted=1&background=1&controls=0"'}
              allow="autoplay;"
              title="Meati™ mission video"
            />
          </div>
        </div>
        <div className="stack-child pt-4 pb-2 px-1 px-lg-0 pt-lg-6 pb-lg-3 pt-xxl-8 pb-xxl-4">
          <Container fluid="lg" className="text-white d-flex flex-column justify-content-center justify-content-lg-between">
            <h1 className="mb-1 mb-sm-3 fst-italic font-swish">
              It's Meati
              <sup>™</sup>
            </h1>
            <div>
              <Row>
                <Col sm={{ span: 6, offset: 6 }} xl={{ span: 5, offset: 7 }} className="d-flex flex-column align-items-end">
                  <p className="subtitle mb-1 text-end">
                    There's a new meat in town. Made from nutrient-rich mushroom root — aka the energy source Mother Nature intended. Now that's Good Energy™.
                  </p>
                  <Link
                    className="btn btn-primary btn-lg"
                    data-ad-element-type="hero cta"
                    data-ad-text="Learn"
                    data-ad-button-id="version 1"
                    data-ad-button-location="hero"
                    to="/what-is-meati"
                    id="heroCTA"
                  >
                    Learn
                  </Link>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </header>
      <BigIngredients />
      <RTBs />
      <LocatorCallOut />
      <CarouselTestimonials />
      <FeaturedCarousel />
      <CookingTipsTiles />
      <MainCTA />
    </Layout>
  )
}

export default HomePage
