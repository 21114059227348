import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Glide from '@glidejs/glide'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'


const CarouselTestimonials = (props) => {

  const {header = "It's Unanimous.", cat = "featured"} = props;

  const slides = useStaticQuery(graphql`
    {
      allWpTestimonial {
        nodes {
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 513, layout: CONSTRAINED, aspectRatio: 1)
                }
              }
            }
          }
          title
          testimonialFields {
            jobTitle
            quote
            whoSaidIt
            blurb
          }
          testimonialCategories {
            nodes {
              slug
            }
          }
        }
      }
    }
  `)

  const currentSlides = slides.allWpTestimonial.nodes.filter(testimonial => {
    return testimonial.testimonialCategories.nodes.some(category => category.slug === cat);
  });


  const slider = new Glide('.glide-testimonials', {
    type: 'carousel',
    autoplay: 3000,
    //autoplay: false,
    gap: 180,
    perView: 1,
    peek: { before: 420, after: 380 },
    breakpoints: {
      1599: {
        gap: 160,
        peek: { before: 380, after: 320 },
      },
      1199: {
        gap: 120,
        peek: { before: 220, after: 200 },
      },
      991: {
        gap: 100,
        peek: { before: 180, after: 140 },
      },
      767: {
        gap: 60,
        peek: { before: 100, after: 100 },
      },
      575: {
        gap: 40,
        peek: { before: 60, after: 60 },
      }
    }
  })

  const numImages = currentSlides.length
  let numImagesLoaded = 0
  const handleImagesLoaded = () => {
    numImagesLoaded++
    if( numImages === numImagesLoaded ) {
      slider.update()
    }
  }

  useEffect(() => {   
    slider.mount()
  }, []);

  return (
    <section className="carousel pt-3 pt-md-4 pt-xl-6 pb-5 pb-md-6 pb-xl-9 text-primary">
        
        <Container fluid className="px-0">
          <h2 className="mb-2 mb-sm-3 mb-xl-4 mb-xxl-5 text-center">{header}</h2>
          <div className="testimonial-wrapper">
            <div className="glide glide-testimonials">
              <div className="glide__track" data-glide-el="track">
                <div className="glide__slides">
                  {
                    currentSlides.map((node, index) => {
                      
                      return(
                        <div className="glide__slide" key={`carousel-${index}`}>
                          <Row className="h-100 gx-md-2 gx-xl-3 gx-xxl-4 align-items-lg-center">
                            <Col sm="5" lg="4">
                              <GatsbyImage
                                image={getImage(node.featuredImage.node.localFile.childImageSharp.gatsbyImageData)}
                                alt={`Meati testimonial by ${node.testimonialFields.whoSaidIt}`}
                                imgClassName="rounded-4"
                                className="rounded-4 mb-12 mb-md-0"
                                imgStyle={{ objectPosition: 'top' }}
                                onLoad={handleImagesLoaded}
                                width={450}
                                loading="eager"
                              />
                            </Col>
                            <Col sm="7" lg="8">
                              <h3 className="fw-bold mb-1">{node.testimonialFields.quote}</h3>
                              <p className="mb-14 lh-sm fs-5">{node.testimonialFields.whoSaidIt}</p>
                              <p className="mb-0 lh-sm">{node.testimonialFields.jobTitle}</p>
                              {node.testimonialFields.blurb &&
                                <p className="glide__slide__blurb mb-0 mt-12">{node.testimonialFields.blurb}</p>
                              }
                            </Col>
                            

                            
                           
                            
                            <div className="glide__slide__label lh-1">
                              
                            </div>
                            
                          </Row>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              <div className="glide__bullets home-glide" data-glide-el="controls[nav]">
                {currentSlides.map((node, index) => (
                  <button id={index} className="glide__bullet shadow-none" data-glide-dir={`=${index}`} key={`button-${index}`} aria-label={`slide ${index} control`} />
                ))}
              </div>
            </div>
          </div>
        </Container>
    </section>
  )
}

export default CarouselTestimonials