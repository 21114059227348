import * as React from 'react'
import Container from 'react-bootstrap/Container'
import ButtonLink from '../common/button-link'

const BigIngredients = () => {
  return (
    <section id="bigIngredients" className="big-ingredients py-2 py-sm-3 py-lg-4 pt-xl-5 pb-xl-3 pt-xxl-4">
      <Container className="d-flex flex-column flex-md-row flex-md-wrap align-items-center align-items-md-start">
        <div className="big-ingredients--header position-relative mb-1 pt-5 pt-sm-2 pb-1 pb-md-2 pb-xl-4 pt-md-5 pt-lg-0 mb-md-0 pe-md-1">
          <h2 className="font-swish text-primary fw-bolder mb-0">
            95% Mushroom
            {' '}
            <span>R</span>
            oot,
          </h2>
          <div className="big-ingredients--header__mushroom-root rounded-4 border border-2 border-primary text-primary position-absolute">
            <div className="text-uppercase fw-bold mb-14">What is Mushroom Root?</div>
            A lot of people call it mycelium, which is a fibrous, root-like network that grows underground! Everywhere you step, mycelium exists
            beneath your feet! Cool, right?
          </div>
          <div className="big-ingredients--header__badge position-absolute rounded-circle bg-info text-white p-12 p-xl-1 text-center d-flex align-items-center justify-content-center text-uppercase lh-1">
            In a meati™ Classic Cutlet
          </div>
        </div>
        <div className="big-ingredients--subhead h5 fw-bolder text-primary mb-2 mb-md-0 ps-md-2">
          The rest:
          <br />
          Less than 2% (Salt, Natural Flavor, Acacia Gum, Oat Fiber, Chickpea Flour).
        </div>
        <ButtonLink btnText="See Nutrition Info" btnLink="/cuts#cutsNutrition" />
      </Container>
    </section>
  )
}

export default BigIngredients
